body {
  background: #183d8e;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1630be, #183d8e);
  background: linear-gradient(to right, #1630be, #183d8e);
  margin: 0;
  padding: 0;
  font-family: "Roboto";
}

h1 {
  color: white;
  text-align: center;
}
ol,
ul {
  padding-left: 30px;
}
li {
  margin-top: 10px;
}

button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: none;
}

.menu button:hover {
  color: #183d8e;
}

.menu {
  background-color: white;
  border-radius: 10%;
  padding: 3%;
}

.menu h2 {
  text-align: center;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 100px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 100px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.game-info {
  margin-left: 20px;
}

.options {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  color: white;
}